import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import YeticoLogo from "../../images/logo_yetico_menu.svg";
import MobileBackground from "../../images/baner-logowanie.png";
import PageTitle from "../../components/main/PageTitle";
import { API_PATH } from "../../../Variables";
import { useAuth } from "../auth/AuthContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Home() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const naviagteToRegister = () => {
  //   navigate('/register');
  // }

  const initialValues = {
    email: "",
    password: "",
    remember: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Nie poprawny adres e-mail")
      .required("Wymagane pole"),
    password: Yup.string().required("Wymagane pole"),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_PATH}/login`, {
        email: values.email,
        password: values.password,
      });

      if (response.data.status === "success") {
        const userData = response.data.user;
        console.log(userData);
        const userToken = response.data.token;
        login(userData, userToken);
        navigate("/home");
        setLoading(false);
      } else {
        setErrors({ api: "Nieprawidłowy e-mail lub hasło." });
        setLoading(false);
      }
    } catch (error) {
      setErrors({ api: "Nieprawidłowy e-mail lub hasło." });
      setLoading(false);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <section className="home">
      <PageTitle title="Logowanie" />
      <div className="home__leftpanel">
        <div className="home__panel">
          <img
            className="home__background--mobile"
            src={MobileBackground}
            alt="Yetico"
          />
          <div className="home__header">
            <NavLink to="/" className="home__header--logo">
              <img src={YeticoLogo} alt="Yetico" />
            </NavLink>
          </div>
          <div className="home__wrapper_bottom">
            <div className="home__text">
              <h1 className="home__title">Hi Yetico!</h1>
              <p className="home__subtitle--bold">Budujemy ciepłe relacje</p>
              <p className="home__subtitle">Akcja sprzedażowo-lojalnościowa</p>
              <p className="home__subtitle--mobile">Hi Yetico!</p>
            </div>
            <div className="home__welcome">
              <h2 className="home__welcome_title">Miło cię widzieć</h2>
              <p className="home__welcome_text">
                Zarejestruj się lub zaloguj na swoje konto
                <br />i zyskuj więcej na współpracy z Yetico.
              </p>
            </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, isSubmitting }) => (
                <Form className="home__form">
                  <div className="home__input_wrapper">
                    <Field
                      className="home__input"
                      type="text"
                      name="email"
                      placeholder="Adres e-mail"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="home__error"
                    />
                  </div>
                  <div className="home__input_wrapper">
                    <Field
                      className="home__input home__input--password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Hasło"
                      autoComplete="current password"
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      className="home__toggle_password"
                      aria-label="Toggle password visibility"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="home__error"
                    />
                  </div>
                  <div className="home_form_wrapper">
                    <div className="home__checkbox">
                      <Field type="checkbox" id="remember" name="remember" />
                      <label htmlFor="remember">Zapamiętaj mnie</label>
                    </div>
                    <div className="home__link">
                      <NavLink to="/remind-password">Przypomnij hasło</NavLink>
                    </div>
                  </div>

                  <div className="home__form_button">
                    {errors.api && (
                      <div className="home__error">{errors.api}</div>
                    )}
                    <button
                      className="home__button--login"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {loading ? <div className="loader"></div> : "Zaloguj się"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
