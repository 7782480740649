import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ChangeIcon from "../../../images/zmiana_danych_ico.svg";
import { API_PATH } from "../../../../Variables";

function LoginData() {
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [initialValues, setInitialValues] = useState({
    name: "",
    lastname: "",
    email: "",
    phonenumber: "",
  });

  useEffect(() => {
    const userJson = localStorage.getItem("user");
    const user = JSON.parse(userJson);
    const userId = user.id;
    const token = localStorage.getItem("token");

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${API_PATH}/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data.user;
        setInitialValues({
          name: userData.name,
          lastname: userData.lastname,
          email: userData.email,
          phonenumber: userData.phone_number,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleEditClick = () => {
    setIsEditable(!isEditable);
    setSuccessMessage("");
  };

  const handleSubmit = async (values, { setErrors }) => {
    const userJson = localStorage.getItem("user");
    const user = JSON.parse(userJson);
    const userId = user.id;
    const token = localStorage.getItem("token");

    try {
      setLoading(true);
      await axios.put(`${API_PATH}/user/${userId}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);

      // Update localStorage with the new user data
      const updatedUser = {
        ...user,
        name: values.name,
        lastname: values.lastname,
        email: values.email,
        phone_number: values.phonenumber,
      };
      localStorage.setItem("user", JSON.stringify(updatedUser));

      setSuccessMessage("Dane zostały zaktualizowane.");
      setIsEditable(false);

      // Refresh the page after a successful update
      window.location.reload();
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 422) {
        setErrors({ email: "Taki email już istnieje" });
      } else {
        console.error("Error updating user data:", error);
      }
    }
  };

  return (
    <div className="account__content_data">
      <p className="account__content_title">Dane logowania</p>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object({
          name: Yup.string().required("Wymagane pole"),
          lastname: Yup.string().required("Wymagane pole"),
          email: Yup.string()
            .email("Niepoprawny adres e-mail")
            .required("Wymagane pole"),
          phonenumber: Yup.string()
            .required("Wymagane pole")
            .matches(/^[0-9]{9}$/, "Numer telefonu musi mieć dokładnie 9 cyfr"),
        })}
        onSubmit={handleSubmit}
      >
        <Form className="account__form">
          <div className="account__input_wrapper">
            <label className="account__label">Imię</label>
            <Field
              className="account__input"
              type="text"
              name="name"
              placeholder="Imię"
              disabled={!isEditable}
            />
            <ErrorMessage
              name="name"
              component="div"
              className="account__error account__error--login"
            />
          </div>
          <div className="account__input_wrapper">
            <label className="account__label">Nazwisko</label>
            <Field
              className="account__input"
              type="text"
              name="lastname"
              placeholder="Nazwisko"
              disabled={!isEditable}
            />
            <ErrorMessage
              name="lastname"
              component="div"
              className="account__error account__error--login"
            />
          </div>
          <div className="account__input_wrapper">
            <label className="account__label">E-mail</label>
            <Field
              className="account__input"
              type="text"
              name="email"
              placeholder="E-mail"
              disabled={!isEditable}
            />
            <ErrorMessage
              name="email"
              component="div"
              className="account__error account__error--login"
            />
          </div>
          <div className="account__input_wrapper">
            <label className="account__label">Nr telefonu</label>
            <Field
              className="account__input"
              type="text"
              name="phonenumber"
              placeholder="Nr telefonu"
              disabled={!isEditable}
            />
            <ErrorMessage
              name="phonenumber"
              component="div"
              className="account__error account__error--login"
            />
          </div>
          <div className="account__buttons_wrapper">
            <button
              type="button"
              className="account__form_change_data_btn"
              onClick={handleEditClick}
            >
              <img src={ChangeIcon} alt="" />{" "}
              {isEditable ? "dezaktywuj zmiany" : "zmień dane"}
            </button>
            <button
              type="submit"
              className="account__form_submit"
              disabled={!isEditable}
            >
              {loading ? <div className="loader"></div> : "zapisz"}
            </button>
          </div>
          {successMessage && (
            <p className="account__success">{successMessage}</p>
          )}
        </Form>
      </Formik>
    </div>
  );
}
export default LoginData;
