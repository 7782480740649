import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/main/PageTitle";
import axios from "axios";
import getImagePath from "../../../../utils/getImagePath";
import ScrollToTopNavLink from "../../../components/main/ScrollToTopNavLink";
import { API_PATH } from "../../../../Variables";
import EkspresImg from "../../../images/70_6_ ekspres2.png";
import Karta150Img from "../../../images/30_12_karta150.png";
import KuchenkaImg from "../../../images/70_7_ kuchenka2.png";
import Karta450Img from "../../../images/70_8_ karta3502.png";

function Main() {
  const [awards30, setAwards30] = useState([]);
  const [awards70, setAwards70] = useState([]);
  const [awards150, setAwards150] = useState([]);
  const [awards250, setAwards250] = useState([]);
  const [awards400, setAwards400] = useState([]);
  const [points, setPoints] = useState(0);
  const userJson = localStorage.getItem("user");
  const user = JSON.parse(userJson);
  const token = localStorage.getItem("token");
  const userId = user?.id;

  const fetchData = async (url, setState) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setState(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    const fetchPoints = async () => {
      try {
        const response = await axios.get(
          `${API_PATH}/user/${userId}/points-without-orders`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPoints(response.data.points);
      } catch (error) {
        console.error("Error fetching points:", error);
      }
    };

    fetchPoints();
  }, [userId, token]);

  useEffect(() => {
    fetchData(`${API_PATH}/awards/30pkt`, setAwards30);
    fetchData(`${API_PATH}/awards/70pkt`, setAwards70);
    fetchData(`${API_PATH}/awards/150pkt`, setAwards150);
    fetchData(`${API_PATH}/awards/250pkt`, setAwards250);
    fetchData(`${API_PATH}/awards/400pkt`, setAwards400);
  }, []);

  const getGlobalImagePath = (imgName, defaultImage) => {
    if (imgName === "Ekspres DELONGHI Dolce Gusto Mini Me") {
      return EkspresImg;
    }
    if (imgName === "Karta przedpłacona 150 zł") {
      return Karta150Img;
    }
    if (imgName === "Kuchenka mikrofalowa AMICA AMGF20M1W") {
      return KuchenkaImg;
    }
    if (imgName === "Karta przedpłacona Pluxee 350 zł") {
      return Karta450Img;
    } else {
      return defaultImage;
    }
  };

  const renderAwards = (awards, tierAllowed) => {
    return awards.map((award) => {
      const imagePath = getImagePath(award.img_name);

      return userId == 1 ? (
        <ScrollToTopNavLink
          to={`/awards/${award.id}`}
          className="awards-page__container_link"
          key={award.id}
          disabled={!tierAllowed}
        >
          <img
            src={getGlobalImagePath(award.name, imagePath)}
            alt={award.name}
          />
          <div className="awards-page__points">{award.points} pkt</div>
          <button
            className={
              tierAllowed
                ? "awards-page__container_button"
                : "award-details__order button-order-disabled"
            }
            disabled={!tierAllowed}
          >
            zobacz szczegóły
          </button>
        </ScrollToTopNavLink>
      ) : (
        <div
          className="awards-page__container_link"
          disabled={true}
          key={award.id}
        >
          <img
            src={getGlobalImagePath(award.name, imagePath)}
            alt={award.name}
          />
          <div className="awards-page__points">{award.points} pkt</div>
          <button
            className={"award-details__order button-order-disabled"}
            disabled={true}
          >
            zobacz szczegóły
          </button>
        </div>
      );
    });
  };

  return (
    <section className="awards-page">
      <PageTitle title="Nagrody" />
      <div className="awards-page__information">
        zamawianie nagród 3-9.12.2024.
      </div>
      <div className="awards-page__inner">
        <h1 className="awards-page__title">Nagrody</h1>
        <p className="awards-page__subtitle">
          Przybij piątkę!
          <br /> 5 progów zakupowych.
        </p>
        <div className="awards-page__main_wrapper">
          <div className="awards-page__container_wrapper" id="awards30">
            <div className="awards-page__container_text">
              <p className="awards-page__container_points">od 30 pkt</p>
              <p className="awards-page__container_title">PRÓG STARTOWY</p>
              {points < 30 && (
                <p className="awards-page__container_info">
                  *Posiadasz zbyt małą ilość punktów by wybierać nagrody z tej
                  grupy
                </p>
              )}
              <div className="awards-page__container_awards_wrapper">
                {renderAwards(awards30, points >= 30)}
              </div>
            </div>
          </div>

          <div className="awards-page__container_wrapper" id="awards70">
            <div className="awards-page__container_text">
              <p className="awards-page__container_points">od 70 pkt</p>
              <p className="awards-page__container_title">PRÓG ZAAWANSOWANY</p>
              {points < 70 && (
                <p className="awards-page__container_info">
                  *Posiadasz zbyt małą ilość punktów by wybierać nagrody z tej
                  grupy
                </p>
              )}
              <div className="awards-page__container_awards_wrapper">
                {renderAwards(awards70, points >= 70)}
              </div>
            </div>
          </div>

          <div className="awards-page__container_wrapper" id="awards150">
            <div className="awards-page__container_text">
              <p className="awards-page__container_points">od 150 pkt</p>
              <p className="awards-page__container_title">PRÓG PREMIUM</p>
              {points < 150 && (
                <p className="awards-page__container_info">
                  *Posiadasz zbyt małą ilość punktów by wybierać nagrody z tej
                  grupy
                </p>
              )}
              <div className="awards-page__container_awards_wrapper">
                {renderAwards(awards150, points >= 150)}
              </div>
            </div>
          </div>

          <div className="awards-page__container_wrapper" id="awards250">
            <div className="awards-page__container_text">
              <p className="awards-page__container_points">od 250 pkt</p>
              <p className="awards-page__container_title">PRÓG EKSPANSYWNY</p>
              {points < 250 && (
                <p className="awards-page__container_info">
                  *Posiadasz zbyt małą ilość punktów by wybierać nagrody z tej
                  grupy
                </p>
              )}
              <div className="awards-page__container_awards_wrapper">
                {renderAwards(awards250, points >= 250)}
              </div>
            </div>
          </div>

          <div className="awards-page__container_wrapper" id="awards400">
            <div className="awards-page__container_text">
              <p className="awards-page__container_points">od 400 pkt</p>
              <p className="awards-page__container_title">PRÓG MISTRZOWSKI</p>
              {points < 400 && (
                <p className="awards-page__container_info">
                  *Posiadasz zbyt małą ilość punktów by wybierać nagrody z tej
                  grupy
                </p>
              )}
              <div className="awards-page__container_awards_wrapper">
                {renderAwards(awards400, points >= 400)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Main;
