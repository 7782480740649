import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import YeticoLogo from "../../images/logo_yetico_menu.svg";
import PageTitle from "../../components/main/PageTitle";
import { API_PATH } from "../../../Variables";
import Modal from "react-modal";
function Pit() {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  // "1" epit
  // "0" paper pit
  const [epit, setEpit] = useState("");

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))) {
      const token = localStorage.getItem("token");
      axios
        .get(`${API_PATH}/user/pit`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const { status } = response.data;
          console.log(response);
          setFetching(false);
          setIsDisabled(response.data.epit == "0" || response.data.epit == "1");
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status == 404) {
            setIsDisabled(false);
          } else {
            console.error("There was an error fetching the quiz data!", error);
          }
        });
    }
  }, []);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const userId = JSON.parse(localStorage.getItem("user")).id;

      const payload = {
        user_id: userId,
        contact_pesel: values.pesel,
        ...values,
      };

      const token = localStorage.getItem("token");
      setLoading(true);
      await axios.put(`${API_PATH}/user/pit`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setModalIsOpen(true);
      setIsDisabled(true);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const apiErrors = error.response.data.errors;
        const translatedErrors = {};

        Object.keys(apiErrors).forEach((key) => {
          translatedErrors[key] = apiErrors[key].map(
            (errorMsg) => errorTranslations[errorMsg] || errorMsg
          );
        });

        setErrors({
          api: "Zapis danych nie powiódł się. Spróbuj ponownie później.",
        });
        setLoading(false);
      } else {
        setErrors({
          api: "Zapis danych nie powiódł się. Spróbuj ponownie później.",
        });
        setLoading(false);
      }
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const closeModal = () => {
    setModalIsOpen(false);
    navigate(`/account`);
  };

  const initialValues = {
    pesel: "",
    street: "",
    house_number: "",
    house_subnumber: "",
    city: "",
    code: "",
    postal: "",
    commune: "",
    district: "",
    province: "",
    contact_pesel: "",
    contact_street: "",
    contact_house_number: "",
    contact_house_subnumber: "",
    contact_city: "",
    contact_code: "",
    contact_postal: "",
    contact_commune: "",
    contact_district: "",
    contact_province: "",
    tax_office: "",
    email: "",
    name: "",
    surname: "",
  };

  const validationSchema =
    epit == "1"
      ? Yup.object({
          pesel: Yup.string().required("Wymagane pole"),
          street: Yup.string().required("Wymagane pole"),
          house_number: Yup.string().required("Wymagane pole"),

          city: Yup.string().required("Wymagane pole"),
          code: Yup.string()
            .matches(
              /^\d{2}-\d{3}$/,
              "Poprawny format kodu pocztowego to (XX-XXX)"
            )
            .required("Wymagane pole"),
          postal: Yup.string().required("Wymagane pole"),
          commune: Yup.string().required("Wymagane pole"),
          district: Yup.string().required("Wymagane pole"),
          province: Yup.string().required("Wymagane pole"),
          email: Yup.string()
            .email("Niepoprawny adres e-mail")
            .required("Wymagane pole"),

          tax_office: Yup.string().required("Wymagane pole"),
          name: Yup.string().required("Wymagane pole"),
          surname: Yup.string().required("Wymagane pole"),
        })
      : Yup.object({
          pesel: Yup.string().required("Wymagane pole"),
          street: Yup.string().required("Wymagane pole"),
          house_number: Yup.string().required("Wymagane pole"),

          city: Yup.string().required("Wymagane pole"),
          code: Yup.string()
            .matches(
              /^\d{2}-\d{3}$/,
              "Poprawny format kodu pocztowego to (XX-XXX)"
            )
            .required("Wymagane pole"),
          postal: Yup.string().required("Wymagane pole"),
          commune: Yup.string().required("Wymagane pole"),
          district: Yup.string().required("Wymagane pole"),
          province: Yup.string().required("Wymagane pole"),
          contact_street: Yup.string().required("Wymagane pole"),
          contact_house_number: Yup.string().required("Wymagane pole"),
          contact_city: Yup.string().required("Wymagane pole"),
          contact_code: Yup.string()
            .matches(
              /^\d{2}-\d{3}$/,
              "Poprawny format kodu pocztowego to (XX-XXX)"
            )
            .required("Wymagane pole"),
          contact_postal: Yup.string().required("Wymagane pole"),
          contact_commune: Yup.string().required("Wymagane pole"),
          contact_district: Yup.string().required("Wymagane pole"),
          contact_province: Yup.string().required("Wymagane pole"),

          tax_office: Yup.string().required("Wymagane pole"),
          name: Yup.string().required("Wymagane pole"),
          surname: Yup.string().required("Wymagane pole"),
        });

  const errorTranslations = {
    "Validation failed": "Niepoprawne dane.",
    "The email has already been taken.": "Adres e-mail jest już zajęty.",
    "The selected code is invalid.": "Nieprawidłowy kod zaproszenia.",
    "Code already assigned to another user":
      "Kod zaproszenia jest już przypisany do innego użytkownika.",
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Notification"
        className="Modal"
        overlayClassName="Overlay"
      >
        <p>
          <strong>Udało się!</strong>
        </p>
        <p>Informacja PIT została wysłane.</p>
        <button onClick={closeModal}>ok</button>
      </Modal>
      <section className="pit">
        <PageTitle title="Informacje PIT" />
        <div className="register__inner">
          <NavLink to="/" className="register__logo">
            <img src={YeticoLogo} alt="Yetico" />
          </NavLink>
          <h1 className="register__title">Informacja PIT</h1>
          <p className="register__info">
            * za poprawność danych odpowiada uczestnik
          </p>
          {isDisabled && !fetching && (
            <h2 className="register__disable_info">
              * Informacja PIT została już złożona
            </h2>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              isSubmitting,
              errors,
            }) => (
              <Form className="register__form">
                <h2 className="register__column_title">
                  Wybierz sposób otrzymania PIT
                </h2>
                <div>
                  <div className="register__radio">
                    <Field
                      type="radio"
                      name="epit"
                      value="1"
                      id="marketing1-yes"
                      onClick={() => {
                        setEpit("1");
                      }}
                    />
                    <label htmlFor="marketing1-yes">
                      Oświadczam, że wyrażam zgodę na otrzymanie od Organizatora
                      PIT za pomocą środków komunikacji elektronicznej na adres
                      e-mail podany przeze mnie poniżej, w celach związanych z
                      udziałem w Programie.
                    </label>
                  </div>
                  <div className="register__radio">
                    <Field
                      type="radio"
                      name="epit"
                      value="0"
                      id="marketing1-no"
                      onClick={() => {
                        setEpit("0");
                      }}
                    />
                    <label htmlFor="marketing1-no">
                      Oświadczam, że wyrażam zgodę na otrzymanie od Organizatora
                      PIT za pomocą Poczty Polskiej na adres korespondencyjny
                      podany przeze mnie poniżej, w celach związanych z udziałem
                      w Programie.
                    </label>
                  </div>
                </div>
                {epit.length > 0 && (
                  <div
                    className={
                      epit == "1"
                        ? "register__form_main_epit"
                        : "register__form_main"
                    }
                  >
                    <div className="register__form_column">
                      <h2 className="register__column_title">
                        Dane podstawowe - dane, na które zostanie wystawiony PIT
                      </h2>

                      {epit == "1" && (
                        <div className="register__input_wrapper">
                          <Field
                            className="register__input"
                            type="text"
                            name="email"
                            placeholder="E-mail"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="register__error"
                          />
                        </div>
                      )}

                      <div className="register__input_wrapper">
                        <Field
                          className="register__input"
                          type="text"
                          name="name"
                          placeholder="Imię"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="register__error"
                        />
                      </div>
                      <div className="register__input_wrapper">
                        <Field
                          className="register__input"
                          type="text"
                          name="surname"
                          placeholder="Nazwisko"
                        />
                        <ErrorMessage
                          name="surname"
                          component="div"
                          className="register__error"
                        />
                      </div>
                      <div className="register__input_wrapper">
                        <Field
                          className="register__input"
                          type="text"
                          name="pesel"
                          placeholder="PESEL"
                        />
                        <ErrorMessage
                          name="pesel"
                          component="div"
                          className="register__error"
                        />
                      </div>
                      <div className="register__input_wrapper">
                        <Field
                          className="register__input"
                          type="text"
                          name="street"
                          placeholder="Ulica"
                        />
                        <ErrorMessage
                          name="street"
                          component="div"
                          className="register__error"
                        />
                      </div>
                      <div className="register__input_wrapper">
                        <Field
                          className="register__input"
                          type="text"
                          name="house_number"
                          placeholder="Numer domu"
                        />
                        <ErrorMessage
                          name="house_number"
                          component="div"
                          className="register__error"
                        />
                      </div>
                      <div className="register__input_wrapper">
                        <Field
                          className="register__input"
                          type="text"
                          name="house_subnumber"
                          placeholder="Numer Mieszkania"
                        />
                      </div>
                      <div className="register__input_wrapper">
                        <Field
                          className="register__input"
                          type="text"
                          name="city"
                          placeholder="Miejscowość"
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="register__error"
                        />
                      </div>
                      <div className="register__input_wrapper">
                        <Field
                          className="register__input"
                          type="text"
                          name="code"
                          placeholder="Kod pocztowy"
                        />
                        <ErrorMessage
                          name="code"
                          component="div"
                          className="register__error"
                        />
                      </div>
                      <div className="register__input_wrapper">
                        <Field
                          className="register__input"
                          type="text"
                          name="postal"
                          placeholder="Poczta"
                        />
                        <ErrorMessage
                          name="postal"
                          component="div"
                          className="register__error"
                        />
                      </div>
                      <div className="register__input_wrapper">
                        <Field
                          className="register__input"
                          type="text"
                          name="commune"
                          placeholder="Gmina"
                        />
                        <ErrorMessage
                          name="commune"
                          component="div"
                          className="register__error"
                        />
                      </div>
                      <div className="register__input_wrapper">
                        <Field
                          className="register__input"
                          type="text"
                          name="district"
                          placeholder="Powiat"
                        />
                        <ErrorMessage
                          name="district"
                          component="div"
                          className="register__error"
                        />
                      </div>
                      <div className="register__input_wrapper">
                        <Field
                          className="register__input"
                          type="text"
                          name="province"
                          placeholder="Województwo"
                        />
                        <ErrorMessage
                          name="province"
                          component="div"
                          className="register__error"
                        />
                      </div>
                      <div className="register__input_wrapper">
                        <label className="register_pit_info">
                          Wybierz urząd skarbowy:
                          <select
                            name="selectedFruit"
                            className="register__input"
                            onChange={(event) =>
                              setFieldValue("tax_office", event.target.value)
                            }
                          >
                            <option disabled selected value>
                              -- Wybierz --
                            </option>
                            <option value="0271">
                              Dolnośląski Urząd Skarbowy we Wrocławiu[0271]
                            </option>
                            <option value="1472">
                              Drugi Mazowiecki Urząd Skarbowy w Warszawie[1472]
                            </option>
                            <option value="1228">
                              Drugi Urząd Skarbowy Kraków[1228]
                            </option>
                            <option value="1436">
                              Drugi Urząd Skarbowy Warszawa-Śródmieście[1436]
                            </option>
                            <option value="2004">
                              Drugi Urząd Skarbowy w Białymstoku[2004]
                            </option>
                            <option value="2404">
                              Drugi Urząd Skarbowy w Bielsku-Białej[2404]
                            </option>
                            <option value="0405">
                              Drugi Urząd Skarbowy w Bydgoszczy[0405]
                            </option>
                            <option value="2410">
                              Drugi Urząd Skarbowy w Częstochowie[2410]
                            </option>
                            <option value="2206">
                              Drugi Urząd Skarbowy w Gdańsku[2206]
                            </option>
                            <option value="2209">
                              Drugi Urząd Skarbowy w Gdyni[2209]
                            </option>
                            <option value="2413">
                              Drugi Urząd Skarbowy w Gliwicach[2413]
                            </option>
                            <option value="3008">
                              Drugi Urząd Skarbowy w Kaliszu[3008]
                            </option>
                            <option value="2417">
                              Drugi Urząd Skarbowy w Katowicach[2417]
                            </option>
                            <option value="2605">
                              Drugi Urząd Skarbowy w Kielcach[2605]
                            </option>
                            <option value="3211">
                              Drugi Urząd Skarbowy w Koszalinie[3211]
                            </option>
                            <option value="0611">
                              Drugi Urząd Skarbowy w Lublinie[0611]
                            </option>
                            <option value="1610">
                              Drugi Urząd Skarbowy w Opolu[1610]
                            </option>
                            <option value="1425">
                              Drugi Urząd Skarbowy w Radomiu[1425]
                            </option>
                            <option value="1822">
                              Drugi Urząd Skarbowy w Rzeszowie[1822]
                            </option>
                            <option value="3216">
                              Drugi Urząd Skarbowy w Szczecinie[3216]
                            </option>
                            <option value="1224">
                              Drugi Urząd Skarbowy w Tarnowie[1224]
                            </option>
                            <option value="0417">
                              Drugi Urząd Skarbowy w Toruniu[0417]
                            </option>
                            <option value="0809">
                              Drugi Urząd Skarbowy w Zielonej Górze[0809]
                            </option>
                            <option value="1009">
                              Drugi Urząd Skarbowy Łódź-Bałuty[1009]
                            </option>
                            <option value="1011">
                              Drugi Urząd Skarbowy Łódź-Górna[1011]
                            </option>
                            <option value="3072">
                              Drugi Wielkopolski Urząd Skarbowy w Kaliszu[3072]
                            </option>
                            <option value="2472">
                              Drugi Śląski Urząd Skarbowy w Bielsku-Białej[2472]
                            </option>
                            <option value="0471">
                              Kujawsko-Pomorski Urząd Skarbowy w
                              Bydgoszczy[0471]
                            </option>
                            <option value="0671">
                              Lubelski Urząd Skarbowy w Lublinie[0671]
                            </option>
                            <option value="0871">
                              Lubuski Urząd Skarbowy w Zielonej Górze[0871]
                            </option>
                            <option value="1271">
                              Małopolski Urząd Skarbowy w Krakowie[1271]
                            </option>
                            <option value="1671">
                              Opolski Urząd Skarbowy w Opolu[1671]
                            </option>
                            <option value="1471">
                              Pierwszy Mazowiecki Urząd Skarbowy w
                              Warszawie[1471]
                            </option>
                            <option value="1435">
                              Pierwszy Urząd Skarbowy Warszawa-Śródmieście[1435]
                            </option>
                            <option value="2003">
                              Pierwszy Urząd Skarbowy w Białymstoku[2003]
                            </option>
                            <option value="2403">
                              Pierwszy Urząd Skarbowy w Bielsku-Białej[2403]
                            </option>
                            <option value="0404">
                              Pierwszy Urząd Skarbowy w Bydgoszczy[0404]
                            </option>
                            <option value="2409">
                              Pierwszy Urząd Skarbowy w Częstochowie[2409]
                            </option>
                            <option value="2205">
                              Pierwszy Urząd Skarbowy w Gdańsku[2205]
                            </option>
                            <option value="2208">
                              Pierwszy Urząd Skarbowy w Gdyni[2208]
                            </option>
                            <option value="2412">
                              Pierwszy Urząd Skarbowy w Gliwicach[2412]
                            </option>
                            <option value="3007">
                              Pierwszy Urząd Skarbowy w Kaliszu[3007]
                            </option>
                            <option value="2416">
                              Pierwszy Urząd Skarbowy w Katowicach[2416]
                            </option>
                            <option value="2604">
                              Pierwszy Urząd Skarbowy w Kielcach[2604]
                            </option>
                            <option value="3210">
                              Pierwszy Urząd Skarbowy w Koszalinie[3210]
                            </option>
                            <option value="1207">
                              Pierwszy Urząd Skarbowy w Krakowie[1207]
                            </option>
                            <option value="0610">
                              Pierwszy Urząd Skarbowy w Lublinie[0610]
                            </option>
                            <option value="1609">
                              Pierwszy Urząd Skarbowy w Opolu[1609]
                            </option>
                            <option value="3023">
                              Pierwszy Urząd Skarbowy w Poznaniu[3023]
                            </option>
                            <option value="1424">
                              Pierwszy Urząd Skarbowy w Radomiu[1424]
                            </option>
                            <option value="1816">
                              Pierwszy Urząd Skarbowy w Rzeszowie[1816]
                            </option>
                            <option value="3215">
                              Pierwszy Urząd Skarbowy w Szczecinie[3215]
                            </option>
                            <option value="1223">
                              Pierwszy Urząd Skarbowy w Tarnowie[1223]
                            </option>
                            <option value="0416">
                              Pierwszy Urząd Skarbowy w Toruniu[0416]
                            </option>
                            <option value="0808">
                              Pierwszy Urząd Skarbowy w Zielonej Górze[0808]
                            </option>
                            <option value="0229">
                              Pierwszy Urząd Skarbowy we Wrocławiu[0229]
                            </option>
                            <option value="1008">
                              Pierwszy Urząd Skarbowy Łódź-Bałuty[1008]
                            </option>
                            <option value="1010">
                              Pierwszy Urząd Skarbowy Łódź-Górna[1010]
                            </option>
                            <option value="3071">
                              Pierwszy Wielkopolski Urząd Skarbowy w
                              Poznaniu[3071]
                            </option>
                            <option value="2471">
                              Pierwszy Śląski Urząd Skarbowy w Sosnowcu[2471]
                            </option>
                            <option value="1871">
                              Podkarpacki Urząd Skarbowy w Rzeszowie[1871]
                            </option>
                            <option value="2071">
                              Podlaski Urząd Skarbowy w Białymstoku[2071]
                            </option>
                            <option value="2271">
                              Pomorski Urząd Skarbowy w Gdańsku[2271]
                            </option>
                            <option value="1473">
                              Trzeci Mazowiecki Urząd Skarbowy w Radomiu[1473]
                            </option>
                            <option value="1449">
                              Trzeci Urząd Skarbowy Warszawa-Śródmieście[1449]
                            </option>
                            <option value="0406">
                              Trzeci Urząd Skarbowy w Bydgoszczy[0406]
                            </option>
                            <option value="2207">
                              Trzeci Urząd Skarbowy w Gdańsku[2207]
                            </option>
                            <option value="0612">
                              Trzeci Urząd Skarbowy w Lublinie[0612]
                            </option>
                            <option value="3217">
                              Trzeci Urząd Skarbowy w Szczecinie[3217]
                            </option>
                            <option value="1208">
                              Urząd Skarbowy Kraków-Krowodrza[1208]
                            </option>
                            <option value="1209">
                              Urząd Skarbowy Kraków-Nowa Huta[1209]
                            </option>
                            <option value="1210">
                              Urząd Skarbowy Kraków-Podgórze[1210]
                            </option>
                            <option value="1211">
                              Urząd Skarbowy Kraków-Prądnik[1211]
                            </option>
                            <option value="1212">
                              Urząd Skarbowy Kraków-Stare Miasto[1212]
                            </option>
                            <option value="1213">
                              Urząd Skarbowy Kraków-Śródmieście[1213]
                            </option>
                            <option value="3020">
                              Urząd Skarbowy Poznań-Grunwald[3020]
                            </option>
                            <option value="3021">
                              Urząd Skarbowy Poznań-Jeżyce[3021]
                            </option>
                            <option value="3022">
                              Urząd Skarbowy Poznań-Nowe Miasto[3022]
                            </option>
                            <option value="3026">
                              Urząd Skarbowy Poznań-Wilda[3026]
                            </option>
                            <option value="3025">
                              Urząd Skarbowy Poznań-Winogrady[3025]
                            </option>
                            <option value="1431">
                              Urząd Skarbowy Warszawa-Bemowo[1431]
                            </option>
                            <option value="1432">
                              Urząd Skarbowy Warszawa-Bielany[1432]
                            </option>
                            <option value="1433">
                              Urząd Skarbowy Warszawa-Mokotów[1433]
                            </option>
                            <option value="1434">
                              Urząd Skarbowy Warszawa-Praga[1434]
                            </option>
                            <option value="1437">
                              Urząd Skarbowy Warszawa-Targówek[1437]
                            </option>
                            <option value="1438">
                              Urząd Skarbowy Warszawa-Ursynów[1438]
                            </option>
                            <option value="1439">
                              Urząd Skarbowy Warszawa-Wawer[1439]
                            </option>
                            <option value="1440">
                              Urząd Skarbowy Warszawa-Wola[1440]
                            </option>
                            <option value="0224">
                              Urząd Skarbowy Wrocław-Fabryczna[0224]
                            </option>
                            <option value="0225">
                              Urząd Skarbowy Wrocław-Krzyki[0225]
                            </option>
                            <option value="0226">
                              Urząd Skarbowy Wrocław-Psie Pole[0226]
                            </option>
                            <option value="0227">
                              Urząd Skarbowy Wrocław-Stare Miasto[0227]
                            </option>
                            <option value="0228">
                              Urząd Skarbowy Wrocław-Śródmieście[0228]
                            </option>
                            <option value="0402">
                              Urząd Skarbowy w Aleksandrowie Kujawskim[0402]
                            </option>
                            <option value="2002">
                              Urząd Skarbowy w Augustowie[2002]
                            </option>
                            <option value="2802">
                              Urząd Skarbowy w Bartoszycach[2802]
                            </option>
                            <option value="1002">
                              Urząd Skarbowy w Bełchatowie[1002]
                            </option>
                            <option value="0602">
                              Urząd Skarbowy w Białej Podlaskiej[0602]
                            </option>
                            <option value="1402">
                              Urząd Skarbowy w Białobrzegach[1402]
                            </option>
                            <option value="3202">
                              Urząd Skarbowy w Białogardzie[3202]
                            </option>
                            <option value="2005">
                              Urząd Skarbowy w Bielsku Podlaskim[2005]
                            </option>
                            <option value="0603">
                              Urząd Skarbowy w Biłgoraju[0603]
                            </option>
                            <option value="1202">
                              Urząd Skarbowy w Bochni[1202]
                            </option>
                            <option value="0202">
                              Urząd Skarbowy w Bolesławcu[0202]
                            </option>
                            <option value="2803">
                              Urząd Skarbowy w Braniewie[2803]
                            </option>
                            <option value="0403">
                              Urząd Skarbowy w Brodnicy[0403]
                            </option>
                            <option value="1602">
                              Urząd Skarbowy w Brzegu[1602]
                            </option>
                            <option value="1203">
                              Urząd Skarbowy w Brzesku[1203]
                            </option>
                            <option value="1003">
                              Urząd Skarbowy w Brzezinach[1003]
                            </option>
                            <option value="1802">
                              Urząd Skarbowy w Brzozowie[1802]
                            </option>
                            <option value="2602">
                              Urząd Skarbowy w Busku-Zdroju[2602]
                            </option>
                            <option value="0203">
                              Urząd Skarbowy w Bystrzycy Kłodzkiej[0203]
                            </option>
                            <option value="2405">
                              Urząd Skarbowy w Bytomiu[2405]
                            </option>
                            <option value="2202">
                              Urząd Skarbowy w Bytowie[2202]
                            </option>
                            <option value="2402">
                              Urząd Skarbowy w Będzinie[2402]
                            </option>
                            <option value="0604">
                              Urząd Skarbowy w Chełmie[0604]
                            </option>
                            <option value="0407">
                              Urząd Skarbowy w Chełmnie[0407]
                            </option>
                            <option value="3037">
                              Urząd Skarbowy w Chodzieży[3037]
                            </option>
                            <option value="2203">
                              Urząd Skarbowy w Chojnicach[2203]
                            </option>
                            <option value="2406">
                              Urząd Skarbowy w Chorzowie[2406]
                            </option>
                            <option value="3203">
                              Urząd Skarbowy w Choszcznie[3203]
                            </option>
                            <option value="1204">
                              Urząd Skarbowy w Chrzanowie[1204]
                            </option>
                            <option value="1403">
                              Urząd Skarbowy w Ciechanowie[1403]
                            </option>
                            <option value="2407">
                              Urząd Skarbowy w Cieszynie[2407]
                            </option>
                            <option value="3002">
                              Urząd Skarbowy w Czarnkowie[3002]
                            </option>
                            <option value="2408">
                              Urząd Skarbowy w Czechowicach-Dziedzicach[2408]
                            </option>
                            <option value="2204">
                              Urząd Skarbowy w Człuchowie[2204]
                            </option>
                            <option value="3204">
                              Urząd Skarbowy w Drawsku Pomorskim[3204]
                            </option>
                            <option value="0812">
                              Urząd Skarbowy w Drezdenku[0812]
                            </option>
                            <option value="2804">
                              Urząd Skarbowy w Działdowie[2804]
                            </option>
                            <option value="0204">
                              Urząd Skarbowy w Dzierżoniowie[0204]
                            </option>
                            <option value="2411">
                              Urząd Skarbowy w Dąbrowie Górniczej[2411]
                            </option>
                            <option value="1205">
                              Urząd Skarbowy w Dąbrowie Tarnowskiej[1205]
                            </option>
                            <option value="1803">
                              Urząd Skarbowy w Dębicy[1803]
                            </option>
                            <option value="2805">
                              Urząd Skarbowy w Elblągu[2805]
                            </option>
                            <option value="2806">
                              Urząd Skarbowy w Ełku[2806]
                            </option>
                            <option value="1404">
                              Urząd Skarbowy w Garwolinie[1404]
                            </option>
                            <option value="2807">
                              Urząd Skarbowy w Giżycku[2807]
                            </option>
                            <option value="3003">
                              Urząd Skarbowy w Gnieźnie[3003]
                            </option>
                            <option value="3205">
                              Urząd Skarbowy w Goleniowie[3205]
                            </option>
                            <option value="0422">
                              Urząd Skarbowy w Golubiu-Dobrzyniu[0422]
                            </option>
                            <option value="1206">
                              Urząd Skarbowy w Gorlicach[1206]
                            </option>
                            <option value="0802">
                              Urząd Skarbowy w Gorzowie Wielkopolskim[0802]
                            </option>
                            <option value="1405">
                              Urząd Skarbowy w Gostyninie[1405]
                            </option>
                            <option value="3004">
                              Urząd Skarbowy w Gostyniu[3004]
                            </option>
                            <option value="2006">
                              Urząd Skarbowy w Grajewie[2006]
                            </option>
                            <option value="1406">
                              Urząd Skarbowy w Grodzisku Mazowieckim[1406]
                            </option>
                            <option value="3005">
                              Urząd Skarbowy w Grodzisku Wielkopolskim[3005]
                            </option>
                            <option value="0408">
                              Urząd Skarbowy w Grudziądzu[0408]
                            </option>
                            <option value="3206">
                              Urząd Skarbowy w Gryficach[3206]
                            </option>
                            <option value="3207">
                              Urząd Skarbowy w Gryfinie[3207]
                            </option>
                            <option value="1407">
                              Urząd Skarbowy w Grójcu[1407]
                            </option>
                            <option value="0233">
                              Urząd Skarbowy w Górze[0233]
                            </option>
                            <option value="0205">
                              Urząd Skarbowy w Głogowie[0205]
                            </option>
                            <option value="1004">
                              Urząd Skarbowy w Głownie[1004]
                            </option>
                            <option value="1603">
                              Urząd Skarbowy w Głubczycach[1603]
                            </option>
                            <option value="2015">
                              Urząd Skarbowy w Hajnówce[2015]
                            </option>
                            <option value="0605">
                              Urząd Skarbowy w Hrubieszowie[0605]
                            </option>
                            <option value="0409">
                              Urząd Skarbowy w Inowrocławiu[0409]
                            </option>
                            <option value="2808">
                              Urząd Skarbowy w Iławie[2808]
                            </option>
                            <option value="0606">
                              Urząd Skarbowy w Janowie Lubelskim[0606]
                            </option>
                            <option value="3006">
                              Urząd Skarbowy w Jarocinie[3006]
                            </option>
                            <option value="1804">
                              Urząd Skarbowy w Jarosławiu[1804]
                            </option>
                            <option value="2414">
                              Urząd Skarbowy w Jastrzębiu-Zdroju[2414]
                            </option>
                            <option value="0206">
                              Urząd Skarbowy w Jaworze[0206]
                            </option>
                            <option value="2415">
                              Urząd Skarbowy w Jaworznie[2415]
                            </option>
                            <option value="1805">
                              Urząd Skarbowy w Jaśle[1805]
                            </option>
                            <option value="0207">
                              Urząd Skarbowy w Jeleniej Górze[0207]
                            </option>
                            <option value="2603">
                              Urząd Skarbowy w Jędrzejowie[2603]
                            </option>
                            <option value="3208">
                              Urząd Skarbowy w Kamieniu Pomorskim[3208]
                            </option>
                            <option value="0208">
                              Urząd Skarbowy w Kamiennej Górze[0208]
                            </option>
                            <option value="2210">
                              Urząd Skarbowy w Kartuzach[2210]
                            </option>
                            <option value="2614">
                              Urząd Skarbowy w Kazimierzy Wielkiej[2614]
                            </option>
                            <option value="1605">
                              Urząd Skarbowy w Kluczborku[1605]
                            </option>
                            <option value="1806">
                              Urząd Skarbowy w Kolbuszowej[1806]
                            </option>
                            <option value="3010">
                              Urząd Skarbowy w Kole[3010]
                            </option>
                            <option value="2007">
                              Urząd Skarbowy w Kolnie[2007]
                            </option>
                            <option value="3011">
                              Urząd Skarbowy w Koninie[3011]
                            </option>
                            <option value="1408">
                              Urząd Skarbowy w Kozienicach[1408]
                            </option>
                            <option value="3209">
                              Urząd Skarbowy w Kołobrzegu[3209]
                            </option>
                            <option value="2606">
                              Urząd Skarbowy w Końskich[2606]
                            </option>
                            <option value="3012">
                              Urząd Skarbowy w Kościanie[3012]
                            </option>
                            <option value="2211">
                              Urząd Skarbowy w Kościerzynie[2211]
                            </option>
                            <option value="1613">
                              Urząd Skarbowy w Krapkowicach[1613]
                            </option>
                            <option value="0607">
                              Urząd Skarbowy w Krasnymstawie[0607]
                            </option>
                            <option value="0608">
                              Urząd Skarbowy w Kraśniku[0608]
                            </option>
                            <option value="3013">
                              Urząd Skarbowy w Krotoszynie[3013]
                            </option>
                            <option value="1807">
                              Urząd Skarbowy w Krośnie[1807]
                            </option>
                            <option value="0803">
                              Urząd Skarbowy w Krośnie Odrzańskim[0803]
                            </option>
                            <option value="1005">
                              Urząd Skarbowy w Kutnie[1005]
                            </option>
                            <option value="2212">
                              Urząd Skarbowy w Kwidzynie[2212]
                            </option>
                            <option value="1604">
                              Urząd Skarbowy w Kędzierzynie-Koźlu[1604]
                            </option>
                            <option value="3009">
                              Urząd Skarbowy w Kępnie[3009]
                            </option>
                            <option value="2809">
                              Urząd Skarbowy w Kętrzynie[2809]
                            </option>
                            <option value="2418">
                              Urząd Skarbowy w Kłobucku[2418]
                            </option>
                            <option value="0209">
                              Urząd Skarbowy w Kłodzku[0209]
                            </option>
                            <option value="1409">
                              Urząd Skarbowy w Legionowie[1409]
                            </option>
                            <option value="0210">
                              Urząd Skarbowy w Legnicy[0210]
                            </option>
                            <option value="1808">
                              Urząd Skarbowy w Lesku[1808]
                            </option>
                            <option value="3014">
                              Urząd Skarbowy w Lesznie[3014]
                            </option>
                            <option value="1809">
                              Urząd Skarbowy w Leżajsku[1809]
                            </option>
                            <option value="1214">
                              Urząd Skarbowy w Limanowej[1214]
                            </option>
                            <option value="0410">
                              Urząd Skarbowy w Lipnie[0410]
                            </option>
                            <option value="1447">
                              Urząd Skarbowy w Lipsku[1447]
                            </option>
                            <option value="1810">
                              Urząd Skarbowy w Lubaczowie[1810]
                            </option>
                            <option value="0211">
                              Urząd Skarbowy w Lubaniu[0211]
                            </option>
                            <option value="0609">
                              Urząd Skarbowy w Lubartowie[0609]
                            </option>
                            <option value="0212">
                              Urząd Skarbowy w Lubinie[0212]
                            </option>
                            <option value="2419">
                              Urząd Skarbowy w Lublińcu[2419]
                            </option>
                            <option value="0213">
                              Urząd Skarbowy w Lwówku Śląskim[0213]
                            </option>
                            <option value="2213">
                              Urząd Skarbowy w Lęborku[2213]
                            </option>
                            <option value="1411">
                              Urząd Skarbowy w Makowie Mazowieckim[1411]
                            </option>
                            <option value="2214">
                              Urząd Skarbowy w Malborku[2214]
                            </option>
                            <option value="1215">
                              Urząd Skarbowy w Miechowie[1215]
                            </option>
                            <option value="1812">
                              Urząd Skarbowy w Mielcu[1812]
                            </option>
                            <option value="2420">
                              Urząd Skarbowy w Mikołowie[2420]
                            </option>
                            <option value="0214">
                              Urząd Skarbowy w Miliczu[0214]
                            </option>
                            <option value="3015">
                              Urząd Skarbowy w Międzychodzie[3015]
                            </option>
                            <option value="0804">
                              Urząd Skarbowy w Międzyrzeczu[0804]
                            </option>
                            <option value="1412">
                              Urząd Skarbowy w Mińsku Mazowieckim[1412]
                            </option>
                            <option value="0411">
                              Urząd Skarbowy w Mogilnie[0411]
                            </option>
                            <option value="2009">
                              Urząd Skarbowy w Mońkach[2009]
                            </option>
                            <option value="2422">
                              Urząd Skarbowy w Myszkowie[2422]
                            </option>
                            <option value="2421">
                              Urząd Skarbowy w Mysłowicach[2421]
                            </option>
                            <option value="1216">
                              Urząd Skarbowy w Myślenicach[1216]
                            </option>
                            <option value="3212">
                              Urząd Skarbowy w Myśliborzu[3212]
                            </option>
                            <option value="1413">
                              Urząd Skarbowy w Mławie[1413]
                            </option>
                            <option value="0412">
                              Urząd Skarbowy w Nakle nad Notecią[0412]
                            </option>
                            <option value="1606">
                              Urząd Skarbowy w Namysłowie[1606]
                            </option>
                            <option value="2810">
                              Urząd Skarbowy w Nidzicy[2810]
                            </option>
                            <option value="1823">
                              Urząd Skarbowy w Nisku[1823]
                            </option>
                            <option value="0215">
                              Urząd Skarbowy w Nowej Rudzie[0215]
                            </option>
                            <option value="0805">
                              Urząd Skarbowy w Nowej Soli[0805]
                            </option>
                            <option value="1414">
                              Urząd Skarbowy w Nowym Dworze Mazowieckim[1414]
                            </option>
                            <option value="2811">
                              Urząd Skarbowy w Nowym Mieście Lubawskim[2811]
                            </option>
                            <option value="1217">
                              Urząd Skarbowy w Nowym Sączu[1217]
                            </option>
                            <option value="1218">
                              Urząd Skarbowy w Nowym Targu[1218]
                            </option>
                            <option value="3016">
                              Urząd Skarbowy w Nowym Tomyślu[3016]
                            </option>
                            <option value="1607">
                              Urząd Skarbowy w Nysie[1607]
                            </option>
                            <option value="3038">
                              Urząd Skarbowy w Obornikach[3038]
                            </option>
                            <option value="2812">
                              Urząd Skarbowy w Olecku[2812]
                            </option>
                            <option value="0216">
                              Urząd Skarbowy w Oleśnicy[0216]
                            </option>
                            <option value="1608">
                              Urząd Skarbowy w Oleśnie[1608]
                            </option>
                            <option value="1219">
                              Urząd Skarbowy w Olkuszu[1219]
                            </option>
                            <option value="2813">
                              Urząd Skarbowy w Olsztynie[2813]
                            </option>
                            <option value="2607">
                              Urząd Skarbowy w Opatowie[2607]
                            </option>
                            <option value="1015">
                              Urząd Skarbowy w Opocznie[1015]
                            </option>
                            <option value="0614">
                              Urząd Skarbowy w Opolu Lubelskim[0614]
                            </option>
                            <option value="2608">
                              Urząd Skarbowy w Ostrowcu Świętokrzyskim[2608]
                            </option>
                            <option value="1416">
                              Urząd Skarbowy w Ostrowi Mazowieckiej[1416]
                            </option>
                            <option value="3017">
                              Urząd Skarbowy w Ostrowie Wielkopolskim[3017]
                            </option>
                            <option value="1415">
                              Urząd Skarbowy w Ostrołęce[1415]
                            </option>
                            <option value="3018">
                              Urząd Skarbowy w Ostrzeszowie[3018]
                            </option>
                            <option value="2814">
                              Urząd Skarbowy w Ostródzie[2814]
                            </option>
                            <option value="1417">
                              Urząd Skarbowy w Otwocku[1417]
                            </option>
                            <option value="0217">
                              Urząd Skarbowy w Oławie[0217]
                            </option>
                            <option value="1220">
                              Urząd Skarbowy w Oświęcimiu[1220]
                            </option>
                            <option value="1016">
                              Urząd Skarbowy w Pabianicach[1016]
                            </option>
                            <option value="1029">
                              Urząd Skarbowy w Pajęcznie[1029]
                            </option>
                            <option value="0615">
                              Urząd Skarbowy w Parczewie[0615]
                            </option>
                            <option value="1418">
                              Urząd Skarbowy w Piasecznie[1418]
                            </option>
                            <option value="2423">
                              Urząd Skarbowy w Piekarach Śląskich[2423]
                            </option>
                            <option value="3019">
                              Urząd Skarbowy w Pile[3019]
                            </option>
                            <option value="1017">
                              Urząd Skarbowy w Piotrkowie Trybunalskim[1017]
                            </option>
                            <option value="2815">
                              Urząd Skarbowy w Piszu[2815]
                            </option>
                            <option value="2609">
                              Urząd Skarbowy w Pińczowie[2609]
                            </option>
                            <option value="3039">
                              Urząd Skarbowy w Pleszewie[3039]
                            </option>
                            <option value="1018">
                              Urząd Skarbowy w Poddębicach[1018]
                            </option>
                            <option value="0234">
                              Urząd Skarbowy w Polkowicach[0234]
                            </option>
                            <option value="1221">
                              Urząd Skarbowy w Proszowicach[1221]
                            </option>
                            <option value="1611">
                              Urząd Skarbowy w Prudniku[1611]
                            </option>
                            <option value="2221">
                              Urząd Skarbowy w Pruszczu Gdańskim[2221]
                            </option>
                            <option value="1421">
                              Urząd Skarbowy w Pruszkowie[1421]
                            </option>
                            <option value="1422">
                              Urząd Skarbowy w Przasnyszu[1422]
                            </option>
                            <option value="1813">
                              Urząd Skarbowy w Przemyślu[1813]
                            </option>
                            <option value="1814">
                              Urząd Skarbowy w Przeworsku[1814]
                            </option>
                            <option value="1448">
                              Urząd Skarbowy w Przysusze[1448]
                            </option>
                            <option value="2424">
                              Urząd Skarbowy w Pszczynie[2424]
                            </option>
                            <option value="2215">
                              Urząd Skarbowy w Pucku[2215]
                            </option>
                            <option value="0616">
                              Urząd Skarbowy w Puławach[0616]
                            </option>
                            <option value="1423">
                              Urząd Skarbowy w Pułtusku[1423]
                            </option>
                            <option value="3213">
                              Urząd Skarbowy w Pyrzycach[3213]
                            </option>
                            <option value="1419">
                              Urząd Skarbowy w Płocku[1419]
                            </option>
                            <option value="1420">
                              Urząd Skarbowy w Płońsku[1420]
                            </option>
                            <option value="2425">
                              Urząd Skarbowy w Raciborzu[2425]
                            </option>
                            <option value="1019">
                              Urząd Skarbowy w Radomsku[1019]
                            </option>
                            <option value="0413">
                              Urząd Skarbowy w Radziejowie[0413]
                            </option>
                            <option value="0617">
                              Urząd Skarbowy w Radzyniu Podlaskim[0617]
                            </option>
                            <option value="3027">
                              Urząd Skarbowy w Rawiczu[3027]
                            </option>
                            <option value="1020">
                              Urząd Skarbowy w Rawie Mazowieckiej[1020]
                            </option>
                            <option value="1815">
                              Urząd Skarbowy w Ropczycach[1815]
                            </option>
                            <option value="2426">
                              Urząd Skarbowy w Rudzie Śląskiej[2426]
                            </option>
                            <option value="2427">
                              Urząd Skarbowy w Rybniku[2427]
                            </option>
                            <option value="0622">
                              Urząd Skarbowy w Rykach[0622]
                            </option>
                            <option value="0414">
                              Urząd Skarbowy w Rypinie[0414]
                            </option>
                            <option value="2610">
                              Urząd Skarbowy w Sandomierzu[2610]
                            </option>
                            <option value="1817">
                              Urząd Skarbowy w Sanoku[1817]
                            </option>
                            <option value="1426">
                              Urząd Skarbowy w Siedlcach[1426]
                            </option>
                            <option value="2428">
                              Urząd Skarbowy w Siemianowicach Śląskich[2428]
                            </option>
                            <option value="2010">
                              Urząd Skarbowy w Siemiatyczach[2010]
                            </option>
                            <option value="1021">
                              Urząd Skarbowy w Sieradzu[1021]
                            </option>
                            <option value="1427">
                              Urząd Skarbowy w Sierpcu[1427]
                            </option>
                            <option value="2611">
                              Urząd Skarbowy w Skarżysku-Kamiennej[2611]
                            </option>
                            <option value="1022">
                              Urząd Skarbowy w Skierniewicach[1022]
                            </option>
                            <option value="1428">
                              Urząd Skarbowy w Sochaczewie[1428]
                            </option>
                            <option value="1429">
                              Urząd Skarbowy w Sokołowie Podlaskim[1429]
                            </option>
                            <option value="2011">
                              Urząd Skarbowy w Sokółce[2011]
                            </option>
                            <option value="2217">
                              Urząd Skarbowy w Sopocie[2217]
                            </option>
                            <option value="2429">
                              Urząd Skarbowy w Sosnowcu[2429]
                            </option>
                            <option value="1818">
                              Urząd Skarbowy w Stalowej Woli[1818]
                            </option>
                            <option value="2612">
                              Urząd Skarbowy w Starachowicach[2612]
                            </option>
                            <option value="3214">
                              Urząd Skarbowy w Stargardzie[3214]
                            </option>
                            <option value="2218">
                              Urząd Skarbowy w Starogardzie Gdańskim[2218]
                            </option>
                            <option value="2613">
                              Urząd Skarbowy w Staszowie[2613]
                            </option>
                            <option value="1612">
                              Urząd Skarbowy w Strzelcach Opolskich[1612]
                            </option>
                            <option value="0218">
                              Urząd Skarbowy w Strzelinie[0218]
                            </option>
                            <option value="1819">
                              Urząd Skarbowy w Strzyżowie[1819]
                            </option>
                            <option value="1222">
                              Urząd Skarbowy w Suchej Beskidzkiej[1222]
                            </option>
                            <option value="0813">
                              Urząd Skarbowy w Sulęcinie[0813]
                            </option>
                            <option value="2012">
                              Urząd Skarbowy w Suwałkach[2012]
                            </option>
                            <option value="3029">
                              Urząd Skarbowy w Szamotułach[3029]
                            </option>
                            <option value="3218">
                              Urząd Skarbowy w Szczecinku[3218]
                            </option>
                            <option value="2816">
                              Urząd Skarbowy w Szczytnie[2816]
                            </option>
                            <option value="1430">
                              Urząd Skarbowy w Szydłowcu[1430]
                            </option>
                            <option value="0423">
                              Urząd Skarbowy w Sępólnie Krajeńskim[0423]
                            </option>
                            <option value="0806">
                              Urząd Skarbowy w Słubicach[0806]
                            </option>
                            <option value="3028">
                              Urząd Skarbowy w Słupcy[3028]
                            </option>
                            <option value="2216">
                              Urząd Skarbowy w Słupsku[2216]
                            </option>
                            <option value="1820">
                              Urząd Skarbowy w Tarnobrzegu[1820]
                            </option>
                            <option value="2430">
                              Urząd Skarbowy w Tarnowskich Górach[2430]
                            </option>
                            <option value="2219">
                              Urząd Skarbowy w Tczewie[2219]
                            </option>
                            <option value="0618">
                              Urząd Skarbowy w Tomaszowie Lubelskim[0618]
                            </option>
                            <option value="1023">
                              Urząd Skarbowy w Tomaszowie Mazowieckim[1023]
                            </option>
                            <option value="0221">
                              Urząd Skarbowy w Trzebnicy[0221]
                            </option>
                            <option value="0418">
                              Urząd Skarbowy w Tucholi[0418]
                            </option>
                            <option value="3032">
                              Urząd Skarbowy w Turku[3032]
                            </option>
                            <option value="2431">
                              Urząd Skarbowy w Tychach[2431]
                            </option>
                            <option value="1821">
                              Urząd Skarbowy w Ustrzykach Dolnych[1821]
                            </option>
                            <option value="1225">
                              Urząd Skarbowy w Wadowicach[1225]
                            </option>
                            <option value="0222">
                              Urząd Skarbowy w Wałbrzychu[0222]
                            </option>
                            <option value="3220">
                              Urząd Skarbowy w Wałczu[3220]
                            </option>
                            <option value="2220">
                              Urząd Skarbowy w Wejherowie[2220]
                            </option>
                            <option value="1226">
                              Urząd Skarbowy w Wieliczce[1226]
                            </option>
                            <option value="1024">
                              Urząd Skarbowy w Wieluniu[1024]
                            </option>
                            <option value="1027">
                              Urząd Skarbowy w Wieruszowie[1027]
                            </option>
                            <option value="2432">
                              Urząd Skarbowy w Wodzisławiu Śląskim[2432]
                            </option>
                            <option value="3034">
                              Urząd Skarbowy w Wolsztynie[3034]
                            </option>
                            <option value="1442">
                              Urząd Skarbowy w Wołominie[1442]
                            </option>
                            <option value="0223">
                              Urząd Skarbowy w Wołowie[0223]
                            </option>
                            <option value="2013">
                              Urząd Skarbowy w Wysokiem Mazowieckiem[2013]
                            </option>
                            <option value="1443">
                              Urząd Skarbowy w Wyszkowie[1443]
                            </option>
                            <option value="0419">
                              Urząd Skarbowy w Wąbrzeźnie[0419]
                            </option>
                            <option value="3033">
                              Urząd Skarbowy w Wągrowcu[3033]
                            </option>
                            <option value="1441">
                              Urząd Skarbowy w Węgrowie[1441]
                            </option>
                            <option value="2433">
                              Urząd Skarbowy w Zabrzu[2433]
                            </option>
                            <option value="1227">
                              Urząd Skarbowy w Zakopanem[1227]
                            </option>
                            <option value="2014">
                              Urząd Skarbowy w Zambrowie[2014]
                            </option>
                            <option value="0620">
                              Urząd Skarbowy w Zamościu[0620]
                            </option>
                            <option value="2434">
                              Urząd Skarbowy w Zawierciu[2434]
                            </option>
                            <option value="1025">
                              Urząd Skarbowy w Zduńskiej Woli[1025]
                            </option>
                            <option value="1026">
                              Urząd Skarbowy w Zgierzu[1026]
                            </option>
                            <option value="0231">
                              Urząd Skarbowy w Zgorzelcu[0231]
                            </option>
                            <option value="1444">
                              Urząd Skarbowy w Zwoleniu[1444]
                            </option>
                            <option value="0230">
                              Urząd Skarbowy w Ząbkowicach Śląskich[0230]
                            </option>
                            <option value="0232">
                              Urząd Skarbowy w Złotoryi[0232]
                            </option>
                            <option value="3036">
                              Urząd Skarbowy w Złotowie[3036]
                            </option>
                            <option value="1006">
                              Urząd Skarbowy w Łasku[1006]
                            </option>
                            <option value="1811">
                              Urząd Skarbowy w Łańcucie[1811]
                            </option>
                            <option value="2008">
                              Urząd Skarbowy w Łomży[2008]
                            </option>
                            <option value="1410">
                              Urząd Skarbowy w Łosicach[1410]
                            </option>
                            <option value="1007">
                              Urząd Skarbowy w Łowiczu[1007]
                            </option>
                            <option value="0613">
                              Urząd Skarbowy w Łukowie[0613]
                            </option>
                            <option value="0621">
                              Urząd Skarbowy w Łęcznej[0621]
                            </option>
                            <option value="1028">
                              Urząd Skarbowy w Łęczycy[1028]
                            </option>
                            <option value="3030">
                              Urząd Skarbowy w Śremie[3030]
                            </option>
                            <option value="3031">
                              Urząd Skarbowy w Środzie Wielkopolskiej[3031]
                            </option>
                            <option value="0219">
                              Urząd Skarbowy w Środzie Śląskiej[0219]
                            </option>
                            <option value="0220">
                              Urząd Skarbowy w Świdnicy[0220]
                            </option>
                            <option value="0807">
                              Urząd Skarbowy w Świebodzinie[0807]
                            </option>
                            <option value="0415">
                              Urząd Skarbowy w Świeciu[0415]
                            </option>
                            <option value="3219">
                              Urząd Skarbowy w Świnoujściu[3219]
                            </option>
                            <option value="0810">
                              Urząd Skarbowy w Żaganiu[0810]
                            </option>
                            <option value="0811">
                              Urząd Skarbowy w Żarach[0811]
                            </option>
                            <option value="0421">
                              Urząd Skarbowy w Żninie[0421]
                            </option>
                            <option value="2435">
                              Urząd Skarbowy w Żorach[2435]
                            </option>
                            <option value="1445">
                              Urząd Skarbowy w Żurominie[1445]
                            </option>
                            <option value="1446">
                              Urząd Skarbowy w Żyrardowie[1446]
                            </option>
                            <option value="2436">
                              Urząd Skarbowy w Żywcu[2436]
                            </option>
                            <option value="3035">
                              Urząd Skarbowy we Wrześni[3035]
                            </option>
                            <option value="0814">
                              Urząd Skarbowy we Wschowie[0814]
                            </option>
                            <option value="0420">
                              Urząd Skarbowy we Włocławku[0420]
                            </option>
                            <option value="0619">
                              Urząd Skarbowy we Włodawie[0619]
                            </option>
                            <option value="2615">
                              Urząd Skarbowy we Włoszczowie[2615]
                            </option>
                            <option value="1012">
                              Urząd Skarbowy Łódź-Polesie[1012]
                            </option>
                            <option value="1014">
                              Urząd Skarbowy Łódź-Widzew[1014]
                            </option>
                            <option value="1013">
                              Urząd Skarbowy Łódź-Śródmieście[1013]
                            </option>
                            <option value="2871">
                              Warmińsko-Mazurski Urząd Skarbowy w
                              Olsztynie[2871]
                            </option>
                            <option value="3271">
                              Zachodniopomorski Urząd Skarbowy w
                              Szczecinie[3271]
                            </option>
                            <option value="1071">
                              Łódzki Urząd Skarbowy w Łodzi[1071]
                            </option>
                            <option value="2671">
                              Świętokrzyski Urząd Skarbowy w Kielcach[2671]
                            </option>
                          </select>
                        </label>
                        <ErrorMessage
                          name="tax_office"
                          component="div"
                          className="register__error"
                        />
                      </div>
                    </div>
                    {epit == "0" && (
                      <div className="register__form_column">
                        <h2 className="register__column_title">
                          Dane kontaktowe - adres korespondencyjny
                        </h2>
                        <button
                          className="register__submit"
                          type="button"
                          onClick={() => {
                            setFieldValue("contact_pesel", values.pesel);
                            setFieldValue("contact_street", values.street);
                            setFieldValue(
                              "contact_house_number",
                              values.house_number
                            );
                            setFieldValue(
                              "contact_house_subnumber",
                              values.house_subnumber
                            );
                            setFieldValue("contact_city", values.city);
                            setFieldValue("contact_code", values.code);
                            setFieldValue("contact_postal", values.postal);
                            setFieldValue("contact_commune", values.commune);
                            setFieldValue("contact_district", values.district);
                            setFieldValue("contact_province", values.province);
                          }}
                        >
                          Skopiuj z danych podstawowych
                        </button>

                        <div className="register__input_wrapper">
                          <Field
                            className="register__input"
                            type="text"
                            name="contact_street"
                            placeholder="Ulica"
                          />
                          <ErrorMessage
                            name="contact_street"
                            component="div"
                            className="register__error"
                          />
                        </div>
                        <div className="register__input_wrapper">
                          <Field
                            className="register__input"
                            type="text"
                            name="contact_house_number"
                            placeholder="Numer domu"
                          />
                          <ErrorMessage
                            name="contact_house_number"
                            component="div"
                            className="register__error"
                          />
                        </div>
                        <div className="register__input_wrapper">
                          <Field
                            className="register__input"
                            type="text"
                            name="contact_house_subnumber"
                            placeholder="Numer Mieszkania"
                          />
                        </div>
                        <div className="register__input_wrapper">
                          <Field
                            className="register__input"
                            type="text"
                            name="contact_city"
                            placeholder="Miejscowość"
                          />
                          <ErrorMessage
                            name="contact_city"
                            component="div"
                            className="register__error"
                          />
                        </div>
                        <div className="register__input_wrapper">
                          <Field
                            className="register__input"
                            type="text"
                            name="contact_code"
                            placeholder="Kod pocztowy"
                          />
                          <ErrorMessage
                            name="contact_code"
                            component="div"
                            className="register__error"
                          />
                        </div>
                        <div className="register__input_wrapper">
                          <Field
                            className="register__input"
                            type="text"
                            name="contact_postal"
                            placeholder="Poczta"
                          />
                          <ErrorMessage
                            name="contact_postal"
                            component="div"
                            className="register__error"
                          />
                        </div>
                        <div className="register__input_wrapper">
                          <Field
                            className="register__input"
                            type="text"
                            name="contact_commune"
                            placeholder="Gmina"
                          />
                          <ErrorMessage
                            name="contact_commune"
                            component="div"
                            className="register__error"
                          />
                        </div>
                        <div className="register__input_wrapper">
                          <Field
                            className="register__input"
                            type="text"
                            name="contact_district"
                            placeholder="Powiat"
                          />
                          <ErrorMessage
                            name="contact_district"
                            component="div"
                            className="register__error"
                          />
                        </div>
                        <div className="register__input_wrapper">
                          <Field
                            className="register__input"
                            type="text"
                            name="contact_province"
                            placeholder="Województwo"
                          />
                          <ErrorMessage
                            name="contact_province"
                            component="div"
                            className="register__error"
                          />
                        </div>
                      </div>
                    )}

                    <div className="register__form_column">
                      <h2 className="register__column_title">Podsumowanie</h2>

                      {errors.api && (
                        <div className="register__error_api">{errors.api}</div>
                      )}
                      <button
                        className={
                          isDisabled
                            ? "register__submit_disabled"
                            : "register__submit"
                        }
                        type="submit"
                        disabled={isSubmitting || isDisabled}
                      >
                        {loading ? (
                          <div className="loader"></div>
                        ) : (
                          "Zapisz dane"
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </section>
    </>
  );
}

export default Pit;
