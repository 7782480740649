import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./assets/styles/main.scss";
import React, { useEffect, useState } from "react";
import Home from "./assets/pages/login/Home";
import RemindPassword from "./assets/pages/login/RemindPassword";
import EmailSended from "./assets/pages/login/EmailSended";
import SetNewPassword from "./assets/pages/login/SetNewPassword";
import Register from "./assets/pages/register/Register";
import HomeLogged from "./assets/pages/home/Home";
import Competition from "./assets/pages/competition/Competition";
import HomeSalesProducts from "./assets/pages/sale-products/Home";
import HomeAwards from "./assets/pages/awards/Home";
import Summary from "./assets/pages/summary/Summary";
import HomeQuizzes from "./assets/pages/quizzes/Home";
import HomeAccount from "./assets/pages/account/Home";
import { AuthProvider } from "./assets/pages/auth/AuthContext";
import PublicRoute from "./assets/pages/auth/PublicRoute";
import ProtectedRoute from "./assets/pages/auth/ProtectedRoute";
import AdminProtectedRoute from "./assets/pages/auth/AdminProtectedRoute";
import HomeApplication from "./assets/pages/application/Home";
import HomeAwardDetails from "./assets/pages/award-details/Home";
import AdminLogin from "./assets/admin-panel/login/Login";
import AdminUsers from "./assets/admin-panel/pages/UsersList/Users";
import AdminUserDetails from "./assets/admin-panel/pages/UsersList/UserDetails";
import AdminUserEdit from "./assets/admin-panel/pages/UsersList/UserEdit";
import AdminImportExport from "./assets/admin-panel/pages/ImportExport/Home";
import Orders from "./assets/admin-panel/pages/Orders/Orders";
import Quiz1 from "./assets/pages/quizzes/quiz_1";
import Quiz2 from "./assets/pages/quizzes/quiz_2";
import Quiz3 from "./assets/pages/quizzes/quiz_3";
import Quiz4 from "./assets/pages/quizzes/quiz_4";
import Quiz5 from "./assets/pages/quizzes/quiz_5";
import axios from "axios";
import { API_PATH } from "./Variables";
import Pit from "./assets/pages/pit/Pit";

function App() {
  const [quizPassed, setQuizPassed] = useState(null);
  const [quizPassed2, setQuizPassed2] = useState(null);
  const [quizPassed3, setQuizPassed3] = useState(null);
  const [quizPassed4, setQuizPassed4] = useState(null);
  const [quizPassed5, setQuizPassed5] = useState(null);

  useEffect(() => {
    document.documentElement.style.backgroundColor = "white";
    document.documentElement.style.color = "black";
    document.body.style.backgroundColor = "white";
    document.body.style.color = "black";

    if (JSON.parse(localStorage.getItem("user"))) {
      const userId = JSON.parse(localStorage.getItem("user")).id;

      axios
        .get(`${API_PATH}/user/${userId}/quiz-1-status`)
        .then((response) => {
          const { quiz_1_status } = response.data;
          console.log(response.data);
          setQuizPassed(quiz_1_status);
        })
        .catch((error) => {
          console.error("There was an error fetching the quiz data!", error);
        });
    }
  }, []);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))) {
      const userId = JSON.parse(localStorage.getItem("user")).id;

      axios
        .get(`${API_PATH}/user/${userId}/quiz-2-status`)
        .then((response) => {
          const { quiz_2_status } = response.data;
          console.log(response.data);
          setQuizPassed2(quiz_2_status);
        })
        .catch((error) => {
          console.error("There was an error fetching the quiz data!", error);
        });
    }
  }, []);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))) {
      const userId = JSON.parse(localStorage.getItem("user")).id;

      axios
        .get(`${API_PATH}/user/${userId}/quiz-3-status`)
        .then((response) => {
          const { quiz_3_status } = response.data;
          console.log(response.data);
          setQuizPassed3(quiz_3_status);
        })
        .catch((error) => {
          console.error("There was an error fetching the quiz data!", error);
        });
    }
  }, []);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))) {
      const userId = JSON.parse(localStorage.getItem("user")).id;

      axios
        .get(`${API_PATH}/user/${userId}/quiz-4-status`)
        .then((response) => {
          const { quiz_4_status } = response.data;
          console.log(response.data);
          setQuizPassed4(quiz_4_status);
        })
        .catch((error) => {
          console.error("There was an error fetching the quiz data!", error);
        });
    }
  }, []);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("user"))) {
      const userId = JSON.parse(localStorage.getItem("user")).id;

      axios
        .get(`${API_PATH}/user/${userId}/quiz-5-status`)
        .then((response) => {
          const { quiz_5_status } = response.data;
          console.log(response.data);
          setQuizPassed5(quiz_5_status);
        })
        .catch((error) => {
          console.error("There was an error fetching the quiz data!", error);
        });
    }
  }, []);

  useEffect(() => {
    const checkUserTokenExist = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${API_PATH}/check-token/${token}`);
        if (response.data === 0) {
          localStorage.clear();
          localStorage.setItem("checkToken", 0);
          window.location.href = "/"; // Przekierowanie do strony głównej
        }
      } catch (error) {
        console.error("Error fetching file upload status:", error);
      }
    };

    // Sprawdzenie, czy token istnieje przed wykonaniem zapytania
    const token = localStorage.getItem("token");
    if (token) {
      checkUserTokenExist();
    }
  }, []);

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<PublicRoute element={<Home />} />} />
            <Route
              path="/remind-password"
              element={<PublicRoute element={<RemindPassword />} />}
            />
            <Route
              path="/email-sended"
              element={<PublicRoute element={<EmailSended />} />}
            />
            <Route
              path="/set-new-password"
              element={<PublicRoute element={<SetNewPassword />} />}
            />

            {/* <Route path="/register" element={<PublicRoute element={<Register />} />} /> */}

            {/* Protected Routes */}
            <Route
              path="/home"
              element={<ProtectedRoute element={<HomeLogged />} />}
            />

            <Route path="/pit" element={<ProtectedRoute element={<Pit />} />} />

            <Route
              path="/competition"
              element={<ProtectedRoute element={<Competition />} />}
            />
            <Route
              path="/sale-products"
              element={<ProtectedRoute element={<HomeSalesProducts />} />}
            />
            <Route
              path="/awards"
              element={<ProtectedRoute element={<HomeAwards />} />}
            />
            <Route
              path="/quizzes"
              element={<ProtectedRoute element={<HomeQuizzes />} />}
            />
            <Route
              path="/account"
              element={<ProtectedRoute element={<HomeAccount />} />}
            />
            {/* <Route path="/application" element={<ProtectedRoute element={<HomeApplication />} />} /> */}
            <Route
              path="/awards/:id"
              element={<ProtectedRoute element={<HomeAwardDetails />} />}
            />
            {quizPassed !== 1 ? (
              <Route
                path="/quizzes/quiz1"
                element={<ProtectedRoute element={<Quiz1 />} />}
              />
            ) : (
              <Route
                path="/quizzes/quiz1"
                element={<ProtectedRoute element={<HomeQuizzes />} />}
              />
            )}
            {quizPassed2 !== 1 ? (
              <Route
                path="/quizzes/quiz2"
                element={<ProtectedRoute element={<Quiz2 />} />}
              />
            ) : (
              <Route
                path="/quizzes/quiz2"
                element={<ProtectedRoute element={<HomeQuizzes />} />}
              />
            )}
            {quizPassed3 !== 1 ? (
              <Route
                path="/quizzes/quiz3"
                element={<ProtectedRoute element={<Quiz3 />} />}
              />
            ) : (
              <Route
                path="/quizzes/quiz3"
                element={<ProtectedRoute element={<HomeQuizzes />} />}
              />
            )}
            {quizPassed4 !== 1 ? (
              <Route
                path="/quizzes/quiz4"
                element={<ProtectedRoute element={<Quiz4 />} />}
              />
            ) : (
              <Route
                path="/quizzes/quiz4"
                element={<ProtectedRoute element={<HomeQuizzes />} />}
              />
            )}
            {quizPassed5 !== 1 ? (
              <Route
                path="/quizzes/quiz5"
                element={<ProtectedRoute element={<Quiz5 />} />}
              />
            ) : (
              <Route
                path="/quizzes/quiz5"
                element={<ProtectedRoute element={<HomeQuizzes />} />}
              />
            )}
            {/* <Route path="/quizzes/quiz2" element={<ProtectedRoute element={<Quiz2 />} />} /> */}

            {/* Admin Routes */}
            <Route path="/admin" element={<AdminLogin />} />
            <Route
              path="/admin/users"
              element={<AdminProtectedRoute element={<AdminUsers />} />}
            />
            <Route
              path="/admin/user-details/:userId"
              element={<AdminProtectedRoute element={<AdminUserDetails />} />}
            />
            <Route
              path="/admin/user-edit/:userId"
              element={<AdminProtectedRoute element={<AdminUserEdit />} />}
            />
            <Route
              path="/admin/import-export"
              element={<AdminProtectedRoute element={<AdminImportExport />} />}
            />
            <Route
              path="/admin/orders"
              element={<AdminProtectedRoute element={<Orders />} />}
            />

            {/* <Route path="/" element={<Time />} /> */}
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
