import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import PageTitle from "../../components/main/PageTitle";
import getImagePath from "../../../utils/getImagePath";
import { API_PATH } from "../../../Variables";
import EkspresImg from "../../images/70_6_ ekspres2.png";
import Karta150Img from "../../images/30_12_karta150.png";
import KuchenkaImg from "../../images/70_7_ kuchenka2.png";
import Karta450Img from "../../images/70_8_ karta3502.png";
import axios from "axios";
import Modal from "react-modal";

Modal.setAppElement("#root");

const Home = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [award, setAward] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [size, setSize] = useState(null);
  const [points, setPoints] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const userJson = localStorage.getItem("user");
  let user = JSON.parse(userJson);
  const [pointsWithoutOrder, setPointsWithoutOrder] = useState(0);
  useEffect(() => {
    const userJson = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const user = JSON.parse(userJson);
    const userId = user?.id;

    const fetchPoints = async () => {
      try {
        const response = await axios.get(
          `${API_PATH}/user/${userId}/points-without-orders`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPointsWithoutOrder(response.data.points);
      } catch (error) {
        console.error("Error fetching points:", error);
      }
    };

    fetchPoints();
  }, [id]);
  const myCurrentTier = () => {
    if (pointsWithoutOrder > 400) {
      return 5;
    } else if (pointsWithoutOrder > 250) {
      return 4;
    } else if (pointsWithoutOrder > 150) {
      return 3;
    } else if (pointsWithoutOrder > 70) {
      return 2;
    } else if (pointsWithoutOrder > 30) {
      return 1;
    }
    return 0;
  };

  const prizeTier = () => {
    const group = award.group;
    if (group == "400pkt") {
      return 5;
    } else if (group == "250pkt") {
      return 4;
    } else if (group == "150pkt") {
      return 3;
    } else if (group == "70pkt") {
      return 2;
    } else if (group == "30pkt") {
      return 1;
    }
    return 0;
  };

  const amIAbleToOrder = () => {
    return myCurrentTier() >= prizeTier();
  };

  useEffect(() => {
    const fetchAwardById = async (id) => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${API_PATH}/awards/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setAward(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching award:", error);
        setLoading(false);
      }
    };

    fetchAwardById(id);
  }, [id]);

  useEffect(() => {
    const userJson = localStorage.getItem("user");
    const user = JSON.parse(userJson);
    const userId = user?.id;

    const fetchPoints = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${API_PATH}/user/${userId}/points`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPoints(response.data.points);
      } catch (error) {
        console.error("Error fetching points:", error);
      }
    };

    fetchPoints();
  }, []);

  const getGlobalImagePath = (imgName, defaultImage) => {
    if (imgName === "Ekspres DELONGHI Dolce Gusto Mini Me") {
      return EkspresImg;
    }
    if (imgName === "Karta przedpłacona 150 zł") {
      return Karta150Img;
    }
    if (imgName === "Kuchenka mikrofalowa AMICA AMGF20M1W") {
      return KuchenkaImg;
    }
    if (imgName === "Karta przedpłacona Pluxee 350 zł") {
      return Karta450Img;
    } else {
      return defaultImage;
    }
  };

  const handleOrder = () => {
    if (user.popup_status == 3) {
      if (points < award.points * quantity) {
        setModalIsOpen(true);
        return;
      }
      if (size) {
        navigate(
          `/summary?id=${id}&quantity=${quantity}&points=${award.points}&size=${size}`
        );
      } else {
        navigate(
          `/summary?id=${id}&quantity=${quantity}&points=${award.points}`
        );
      }
    }
  };

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!award) {
    return <p>Award not found.</p>;
  }

  return (
    <>
      <Header />
      <section className="award-details">
        <PageTitle title={`${award.name}`} />
        <div className="awards-page__information">
          zamawianie nagród 3-9.12.2024.
        </div>
        <div className="award-details__inner">
          <div className="award-details__points">
            <strong>od {award.group.replace("pkt", "")} pkt</strong>
          </div>
          <div className="award-details__title">{award.group_name}</div>
          <div className="award-details__wrapper">
            <div className="award-details__column1">
              <img
                src={getGlobalImagePath(
                  award.name,
                  getImagePath(award.img_name)
                )}
                alt={award.name}
                className="award-details__image"
              />
            </div>
            <div className="award-details__column2">
              <div className="award-details__column2_title">{award.name}</div>
              <div className="award-details__column2_price">
                {award.points} pkt
              </div>
              <div className="award-details__column2_wrapper">
                {award.id == 4 && (
                  <div>
                    <label>Rozmiar: </label>
                    <select
                      className="select-size-input"
                      value={size}
                      onChange={handleSizeChange}
                    >
                      <option value="s">S</option>
                      <option value="m">M</option>
                      <option value="l">L</option>
                      <option value="xl">XL</option>
                      <option value="2xl">2XL</option>
                      <option value="3xl">3XL</option>
                    </select>
                  </div>
                )}

                {award.id == 7 && (
                  <div>
                    <label>Rozmiar: </label>
                    <select
                      className="select-size-input"
                      value={size}
                      onChange={handleSizeChange}
                    >
                      <option value="xs">XS</option>
                      <option value="s">S</option>
                      <option value="m">M</option>
                      <option value="l">L</option>
                      <option value="xl">XL</option>
                      <option value="2xl">2XL</option>
                      <option value="3xl">3XL</option>
                      <option value="4xl">4XL</option>
                      <option value="5xl">5XL</option>
                    </select>
                  </div>
                )}

                {award.id == 8 && (
                  <div>
                    <label>Rozmiar: </label>
                    <select
                      className="select-size-input"
                      value={size}
                      onChange={handleSizeChange}
                    >
                      <option value="s">S</option>
                      <option value="m">M</option>
                      <option value="l">L</option>
                      <option value="xl">XL</option>
                      <option value="2xl">2XL</option>
                      <option value="3xl">3XL</option>
                      <option value="4xl">4XL</option>
                    </select>
                  </div>
                )}

                {award.id == 9 && (
                  <div>
                    <label>Rozmiar: </label>
                    <select
                      className="select-size-input"
                      value={size}
                      onChange={handleSizeChange}
                    >
                      <option value="44">44</option>
                      <option value="46">46</option>
                      <option value="48">48</option>
                      <option value="50">50</option>
                      <option value="27">27</option>
                      <option value="98">98</option>
                      <option value="52">52</option>
                      <option value="54">54</option>
                      <option value="30">30</option>
                      <option value="59">59</option>
                      <option value="102">102</option>
                      <option value="56">56</option>
                      <option value="58">58</option>
                      <option value="104">104</option>
                      <option value="110">110</option>
                      <option value="60">60</option>
                      <option value="62">62</option>
                      <option value="64">64</option>
                    </select>
                  </div>
                )}
              </div>

              <p className="award-details__column2_description_button">
                zobacz szczegóły <div className="triangle"></div>
              </p>
              <p
                className="award-details__column2_description"
                dangerouslySetInnerHTML={{
                  __html: award.desc.replace(/\\n/g, "<br><br>"),
                }}
              ></p>
              <div className="awards-page__warning awards-page__warning--award">
                <div className="awards-page__warning_icon awards-page__warning_icon--award">
                  !
                </div>
                <p>
                  W przypadku niedostępności produktu zaproponujemy model o
                  podobnych parametrach.
                </p>
              </div>
            </div>
          </div>
          {award.id == 4 && (
            <div className="size-table">
              <strong>Tabela rozmiarów: </strong>
              <br />
              <br />
              <table>
                <thead>
                  <tr>
                    <th>
                      <strong>Rozmiar</strong>
                    </th>
                    <th>
                      <strong>S</strong>
                    </th>
                    <th>
                      <strong>M</strong>
                    </th>
                    <th>
                      <strong>L</strong>
                    </th>
                    <th>
                      <strong>XL</strong>
                    </th>
                    <th>
                      <strong>2XL</strong>
                    </th>
                    <th>
                      <strong>3XL</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>długość (cm)</strong>
                    </td>
                    <td data-th="S ">66</td>
                    <td data-th="M ">70</td>
                    <td data-th="L ">74</td>
                    <td data-th="XL ">76</td>
                    <td data-th="2XL ">78</td>
                    <td data-th="3XL ">82</td>
                  </tr>

                  <tr>
                    <td>
                      <strong>szerokość (cm)</strong>
                    </td>
                    <td data-th="S ">48</td>
                    <td data-th="M ">52</td>
                    <td data-th="L ">55</td>
                    <td data-th="XL ">58</td>
                    <td data-th="2XL ">61</td>
                    <td data-th="3XL ">65</td>
                  </tr>

                  <tr>
                    <td>
                      <strong>długość rękawa (cm)</strong>
                    </td>
                    <td data-th="S ">18</td>
                    <td data-th="M ">22</td>
                    <td data-th="L ">23</td>
                    <td data-th="XL ">24</td>
                    <td data-th="2XL ">25</td>
                    <td data-th="3XL ">26</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {award.id == 7 && (
            <div className="size-table">
              <strong>Tabela rozmiarów: </strong>
              <br />
              <br />
              <table>
                <thead>
                  <tr>
                    <th>
                      <strong>Rozmiar</strong>
                    </th>
                    <th>
                      <strong>XS</strong>
                    </th>
                    <th>
                      <strong>S</strong>
                    </th>
                    <th>
                      <strong>M</strong>
                    </th>
                    <th>
                      <strong>L</strong>
                    </th>
                    <th>
                      <strong>XL</strong>
                    </th>
                    <th>
                      <strong>2XL</strong>
                    </th>
                    <th>
                      <strong>3XL</strong>
                    </th>
                    <th>
                      <strong>4XL</strong>
                    </th>
                    <th>
                      <strong>5XL</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>długość (cm)</strong>
                    </td>
                    <td data-th="XS ">65</td>
                    <td data-th="S ">66</td>
                    <td data-th="M ">68</td>
                    <td data-th="L ">71</td>
                    <td data-th="XL ">76</td>
                    <td data-th="2XL ">78</td>
                    <td data-th="3XL ">82</td>
                    <td data-th="4XL ">83</td>
                    <td data-th="5XL ">85</td>
                  </tr>

                  <tr>
                    <td>
                      <strong>szerokość (cm)</strong>
                    </td>
                    <td data-th="XS ">51</td>
                    <td data-th="S ">53</td>
                    <td data-th="M ">56</td>
                    <td data-th="L ">58</td>
                    <td data-th="XL ">61</td>
                    <td data-th="2XL ">64</td>
                    <td data-th="3XL ">68</td>
                    <td data-th="4XL ">72</td>
                    <td data-th="5XL ">76</td>
                  </tr>

                  <tr>
                    <td>
                      <strong>długość rękawa (cm)</strong>
                    </td>
                    <td data-th="XS ">61</td>
                    <td data-th="S ">62</td>
                    <td data-th="M ">64</td>
                    <td data-th="L ">65</td>
                    <td data-th="XL ">66</td>
                    <td data-th="2XL ">67</td>
                    <td data-th="3XL ">69</td>
                    <td data-th="4XL ">70</td>
                    <td data-th="5XL ">72</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {award.id == 8 && (
            <div className="size-table">
              <strong>Tabela rozmiarów: </strong>
              <br />
              <br />
              <table>
                <thead>
                  <tr>
                    <th>
                      <strong>Rozmiar</strong>
                    </th>
                    <th>
                      <strong>S</strong>
                    </th>
                    <th>
                      <strong>M</strong>
                    </th>
                    <th>
                      <strong>L</strong>
                    </th>
                    <th>
                      <strong>XL</strong>
                    </th>
                    <th>
                      <strong>2XL</strong>
                    </th>
                    <th>
                      <strong>3XL</strong>
                    </th>
                    <th>
                      <strong>4XL</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>długość (cm)</strong>
                    </td>
                    <td data-th="S ">66</td>
                    <td data-th="M ">68</td>
                    <td data-th="L ">70</td>
                    <td data-th="XL ">72</td>
                    <td data-th="2XL ">74</td>
                    <td data-th="3XL ">75</td>
                    <td data-th="4XL ">76</td>
                  </tr>

                  <tr>
                    <td>
                      <strong>szerokość (cm)</strong>
                    </td>
                    <td data-th="S ">53</td>
                    <td data-th="M ">55,5</td>
                    <td data-th="L ">58</td>
                    <td data-th="XL ">61,5</td>
                    <td data-th="2XL ">65</td>
                    <td data-th="3XL ">69</td>
                    <td data-th="4XL ">73</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {award.id == 9 && (
            <div className="size-table">
              <strong>Tabela rozmiarów: </strong>
              <br />
              <br />
              <table>
                <thead>
                  <tr>
                    <th>
                      <strong>Rozmiar</strong>
                    </th>
                    <th>
                      <strong>44 </strong>
                    </th>
                    <th>
                      <strong>46 </strong>
                    </th>
                    <th>
                      <strong>48 </strong>
                    </th>
                    <th>
                      <strong>50 </strong>
                    </th>
                    <th>
                      <strong>27 </strong>
                    </th>
                    <th>
                      <strong>98 </strong>
                    </th>
                    <th>
                      <strong>52 </strong>
                    </th>
                    <th>
                      <strong>54 </strong>
                    </th>
                    <th>
                      <strong>30 </strong>
                    </th>
                    <th>
                      <strong>59 </strong>
                    </th>
                    <th>
                      <strong>102</strong>
                    </th>
                    <th>
                      <strong>56 </strong>
                    </th>
                    <th>
                      <strong>58 </strong>
                    </th>
                    <th>
                      <strong>104</strong>
                    </th>
                    <th>
                      <strong>110</strong>
                    </th>
                    <th>
                      <strong>60 </strong>
                    </th>
                    <th>
                      <strong>62 </strong>
                    </th>
                    <th>
                      <strong>64 </strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>obwód klatki (cm)</strong>
                    </td>
                    <td data-th="44 ">84-88</td>
                    <td data-th="46 ">88-92</td>
                    <td data-th="48 ">92-96</td>
                    <td data-th="50 ">96-100</td>
                    <td data-th="27 ">104-108</td>
                    <td data-th="98 ">92-96</td>
                    <td data-th="52 ">100-104</td>
                    <td data-th="54 ">104-108</td>
                    <td data-th="30 ">116-120</td>
                    <td data-th="59 ">112-116</td>
                    <td data-th="102 ">96-100</td>
                    <td data-th="56 ">108-112</td>
                    <td data-th="58 ">112-116</td>
                    <td data-th="104 ">92-96</td>
                    <td data-th="110 ">104-108</td>
                    <td data-th="60 ">116-120</td>
                    <td data-th="62 ">120-124</td>
                    <td data-th="64 ">124-128</td>
                  </tr>

                  <tr>
                    <td>
                      <strong>obwód pasa (cm)</strong>
                    </td>
                    <td data-th="44 ">76-80</td>
                    <td data-th="46 ">80-84</td>
                    <td data-th="48 ">84-88</td>
                    <td data-th="50 ">88-92</td>
                    <td data-th="27 ">96-104</td>
                    <td data-th="98 ">84-88</td>
                    <td data-th="52 ">92-96</td>
                    <td data-th="54 ">96-100</td>
                    <td data-th="30 ">108-112</td>
                    <td data-th="59 ">120-128</td>
                    <td data-th="102 ">88-92</td>
                    <td data-th="56 ">100-104</td>
                    <td data-th="58 ">104-108</td>
                    <td data-th="104 ">84-88</td>
                    <td data-th="110 ">96-100</td>
                    <td data-th="60 ">108-112</td>
                    <td data-th="62 ">112-116</td>
                    <td data-th="64 ">116-120</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>wzrost (cm)</strong>
                    </td>
                    <td data-th="44 ">158-164</td>
                    <td data-th="46 ">164-170</td>
                    <td data-th="48 ">170-176</td>
                    <td data-th="50 ">170-176</td>
                    <td data-th="27 ">170-176</td>
                    <td data-th="98 ">176-182</td>
                    <td data-th="52 ">176-182</td>
                    <td data-th="54 ">176-182</td>
                    <td data-th="30 ">176-182</td>
                    <td data-th="59 ">176-182</td>
                    <td data-th="102 ">182-188</td>
                    <td data-th="56 ">182-188</td>
                    <td data-th="58 ">182-188</td>
                    <td data-th="104 ">188-194</td>
                    <td data-th="110 ">188-194</td>
                    <td data-th="60 ">188-194</td>
                    <td data-th="62 ">188-194</td>
                    <td data-th="64 ">194-200</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </section>
      <Footer />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Insufficient Points"
        className="Modal"
        overlayClassName="Overlay"
      >
        <p>
          <strong>Masz za mało punktów, aby zamówić tę nagrodę.</strong>
        </p>
        <button onClick={closeModal}>ok</button>
      </Modal>
    </>
  );
};

export default Home;
